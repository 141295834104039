import React from 'react'
import cx from 'classnames'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/5-tipu-jak-hubnout-i-bez-diet.jpg'
import styles from './post-grid.module.css'

const TipuJakHubnoutBezDiet = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'5 tipů, jak hubnout i bez diet | NutritionPro'}
        description={
          'Chcete začít hubnout, ale nechce se vám dodržovat žádné striktní diety? Skvěle! Přinášíme vám 5 tipů, jak na to:'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/5-tipu-jak-hubnout-i-bez-diet">
            5 tipů, jak hubnout i bez diet
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero title="5 tipů, jak hubnout i bez diet" date="17.04.2023" />
            <div>
              <p className={styles.postText}>
                Chcete začít hubnout, ale nechce se vám dodržovat žádné striktní
                diety? Skvěle! Přinášíme vám 5 tipů, jak na to:
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="vegetariánské jídlo"
              />

              <h5 className={styles.postTitle}>
                1. Zvyšte svou fyzickou aktivitu
              </h5>
              <p className={styles.postText}>
                Když se snažíte zhubnout, fyzická aktivita může být{' '}
                <b>velmi účinným pomocníkem</b>. To však neznamená, že musíte
                trávit hodiny v posilovně nebo běhat maratóny. Místo toho můžete
                začít tím, že <b>zvýšíte přirozený pohyb</b>, to znamená, že
                můžete začít <b>více chodit</b>, například do obchodu, do práce
                nebo aspoň na zastávku autobusu místo toho, abyste jeli autem.
                Když už musíte jet autem, zaparkujte o ulici dál a projděte se.
                Ve svém telefonu či hodinkách můžete každý den{' '}
                <b>kontrolovat, kolik kroků jste ušli</b>. Můžete si určit{' '}
                <b>denní cíl</b>. Mělo by to být ideálně okolo 10 tisíc kroků.
                Také zapomeňte na výtah a vycházejte schody. Ať už jdete do
                jakéhokoliv patra. Dále můžete zařadit pravidelné procházky do
                přírody nebo se jít čas od času proběhnout. Také zařaďte
                aktivity, <b>co vás baví</b>, bude pro vás snažší je{' '}
                <b>pravidelně vykonávat</b>. Opět si dejte nějaký reálný cíl,
                například cvičení 2x týdně. Může to být třeba jízda na kole,
                jóga nebo fitbox. Vlastně úplně cokoliv. Díky pravidelnému
                pohybu <b>zlepšíte svůj životní styl</b>, celkové zdraví a také{' '}
                <b>podpoříte hubnutí bez striktních diet</b>.
              </p>

              <p className={styles.postText}>
                <b>TIP:</b> S jakým sportem nejrychleji zhubnete?{' '}
                <a
                  href="https://nutritionpro.cz/blog/s-jakym-sportem-nejrychleji-zhubnu/"
                  target="_blank"
                >
                  Zjistěte zde
                </a>
                .
              </p>

              <h5 className={styles.postTitle}>2. Jezte pravidelně</h5>
              <p className={styles.postText}>
                Pravidelný příjem energie a živin vám zaručí, že{' '}
                <b>nebudete mít v průběhu dne hlad ani chutě</b>, nebudete proto
                mít touhy <b>mlsat nebo se přejídat</b>, což bývá častým
                problémem u všemožných diet. Pokud jíte málo, bude vaše tělo{' '}
                <b>vyžadovat energii</b> a vás budou přepadat chutě na kde co.
                To vám může dietu <b>zásadně narušit</b> a zvýšit kalorický
                příjem tak, že budete ve výsledku ještě <b>přibírat</b>. Ruku v
                ruce s tím jde samozřejmě to, co jíte. Váš jídelníček by měl být{' '}
                <b>vyvážený a kvalitní</b>, s dostatkem plnohodnotných bílkovin,
                komplexních sacharidů, vlákniny a tuků, ideálně převážně
                rostlinných. Pokud budete tyto zásady dodržovat, nestane se vám,
                že vás přepadne hlad a{' '}
                <b>místo kvalitního jídla si dáte fastfood</b>. Díky tomu budete
                mít kontrolu nad svým příjmem a bude se vám i snadněji hubnout.
              </p>

              <h5 className={styles.postTitle}>3. Zvolte Krabičkovou dietu</h5>
              <p className={styles.postText}>
                Když už jsem zmínila pravidelné jezení, nejjednodušší způsob,
                jak docílit toho, že budete opravdu{' '}
                <b>pravidelně a kvalitně jíst</b>, je{' '}
                <b>
                  <a href="https://nutritionpro.cz" target="_blank">
                    krabičková dieta
                  </a>
                </b>
                . Díky krabičkám ušetříte spoustu času a budete mít jistotu, že
                je pro vás každý den připravené{' '}
                <b>chutné, zdravé a vyvážené jídlo</b>. Také budete mít{' '}
                <b>pod kontrolou svůj příjem</b> a vždy budete vědět, co za
                jídlo vás čeká. Ať už máte o zdravém stravování povědomí či ne,
                krabičky vám <b>zaručeně pomohou</b>. Stačí zvolit{' '}
                <b>hubnoucí program</b>, ve kterém vás čekají{' '}
                <b>porce na míru</b> přesně tak, abyste byli{' '}
                <b>v lehkém kalorickém deficitu</b> a podpořili hubnutí, ale{' '}
                <b>nestrádali</b>.{' '}
                <a href="https://nutritionpro.cz" target="_blank">
                  Krabičky
                </a>{' '}
                vám{' '}
                <b>
                  ušetří neskutečné množství času s přípravou i propočítáváním
                  kalorií
                </b>
                .
              </p>

              <h5 className={styles.postTitle}>
                4. Omezte cukrovinky a slazené nápoje
              </h5>
              <p className={styles.postText}>
                Zkuste vynechat potraviny, o kterých víte, že by se měly
                konzumovat <b>pouze výjimečně</b>. Jedná například o různé{' '}
                <b>cukrovinky, snacky, sladkosti, ale také o slazené nápoje</b>.
                Tyto lahůdky mají obvykle <b>vysokou kalorickou hodnotu</b> a
                obsahují <b>velké množství cukru a případně i tuků</b>. Zbytečně
                tak navyšujete svůj příjem a ani si to nemusíte uvědomit.
                Slazené nápoje postupně <b>nahraďte čistou vodou</b> a namísto
                každodenního mlsání radši <b>pravidelně a dostatečně jezte</b>{' '}
                kvalitní stravu. Když budete mít dostatek živin, nebudete mít na
                mlsání ani pomyšlení.
              </p>

              <h5 className={styles.postTitle}>5. Snižte příjem alkoholu</h5>
              <p className={styles.postText}>
                Alkohol obsahuje <b>velké množství kalorií</b> a nemá{' '}
                <b>téměř žádnou výživovou hodnotu</b>. Pokud si dáte skleničku
                vína či piva, bude to{' '}
                <b>kaloricky srovnatelné s menší svačinou</b>. Rozdíl je v tom,
                že <b>svačina vám dodá živiny</b>, které potřebujete, alkohol
                nikoliv. Pití alkoholu také výrazně{' '}
                <b>zpomaluje metabolismus</b>. Alkohol též{' '}
                <b>narušuje hormonální rovnováhu a kvalitní spánek</b>, který je
                pro hubnutí velmi důležitý. S konzumací alkoholu se pojí hned
                několik dalších nevýhod,{' '}
                <b>je proto nasnadě ho vynechat nebo alespoň omezit</b>,
                minimálně po dobu hubnutí.
              </p>

              <p className={styles.postText}>
                Věřím, že vám tyto tipy pomohou cestu za vysněnou postavou
                zjednodušit!
              </p>

              <p className={styles.postText}>
                Autorka: Ing. Kristina Heřmanová
              </p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default TipuJakHubnoutBezDiet
